
import React, {useState} from "react"

import ProductRequestEditorPage from "../../components/negosyonow/controls/productrequesteditor";

const negosyonowAPI = require("../../../lib/requestNegosyownow")
const webappsAPI = require("../../../lib/requestWebapps");

const basicFields = [
	{"label":"Primary Image", "field": "nnproductrequest_image", "value": "", "input": "image", "mode": "readonly"},
	{"label":"Seller", "field": "onsorganization_name", "value": "", "input": "picker", "mode": "readonly"},
	{"label":"Seller SKU", "field": "nnproductrequest_orgsku", "value": "", "input": "textbox", "mode": "readonly"},
	{"label":"Parent Product", "field": "nnproductalias_name", "value": "", "input": "picker", "mode": "readonly"},


	{"label":"Full Name / Display", "field": "nnproductrequest_fullname", "value": "", "input": "textbox", "mode": "readonly"},

	{"label":"Brand", "field": "nnbrand_name", "value": "", "input": "picker", "mode": "readonly"},
	{"label":"Product Line", "field": "nnproductrequest_name", "value": "", "input": "textbox", "mode": "readonly", "info":"Internal name used for searching/tagging parent products"},

	{"label":"Variant/Promo Display", "field": "nnproductrequest_subname", "value": "", "input": "textbox", "mode": "readonly", "info":"Displayed under product variant options in website"},


	{"label":"Promo Image", "field": "nnproductrequest_promoimage", "value": "", "input": "image", "mode": "readonly"},
	{"label":"Promo Item", "field": "nnproductrequest_promoitem", "value": "", "input": "checkbox", "mode": "readonly"},
	{"label":"Price", "field": "nnproductrequest_price", "value": "", "input": "currency", "mode": "readonly"},

	{"label":"Last Updated", "field": "nnproductrequest_lastupdate", "value": "", "input": "updatetime", "mode": "readonly"},

	{"label":"Write-up", "field": "nnproductrequest_description", "value": "", "input": "textarea", "mode": "readonly"},
	{"label":"Teaser/Summary", "field": "nnproductrequest_summary", "value": "", "input": "textarea", "mode": "readonly"},

	{"label":"Seller Notes", "field": "nnproductrequest_usernotes", "value": "", "input": "textarea", "mode": "readonly", "info":"Request-related information"},

	{"label":"Status", "field": "docstatus_id", "value": "", "input": "combo", "mode": "required", "options": [
		//{"value":1,"display":"Draft"},
		{"value":2,"display":"For Approval"},
		{"value":3,"display":"Approved"},
		{"value":4,"display":"Declined"},
		{"value":5,"display":"For Adjustments"}
	]},
	{"label":"Feedback", "field": "nnproductrequest_feedback", "value": "", "input": "textarea", "mode": "normal"},
];

const productDimensionfields = [
	{"label":"Weight (Kilograms)", "field": "nnproductrequest_pkgweight", "value": "", "input": "numeric", "mode": "readonly"},
	{"label":"Length (cm)", "field": "nnproductrequest_pkglength", "value": "", "input": "numeric", "mode": "readonly"},
	{"label":"Width (cm)", "field": "nnproductrequest_pkgwidth", "value": "", "input": "numeric", "mode": "readonly"},
	{"label":"Height (cm)", "field": "nnproductrequest_pkgheight", "value": "", "input": "numeric", "mode": "readonly"},
	{"label":"Volume (Liters)", "field": "nnproductrequest_pkgvolume", "value": "", "input": "numeric", "mode": "readonly"},
	{"label":"Irregular/Not Boxed", "field": "nnproductrequest_pkgirregular", "value": "", "input": "checkbox", "mode": "readonly"},
	{"label":"Storage Instructions", "field": "nnproductstorage_id", "value": "", "input": "picker", "mode": "readonly"},
	{"label":"Shelf Life", "field": "nnproductrequest_shelflife", "value": "", "input": "textbox", "mode": "readonly"},
];

const subformFields = [
	{
		"subformid": "nnproductimage",
		"label": "Images",
		"table": "nnproductimage",
		"sort": "nnproductimage_primary desc",
		"mobilerowtitlefieldidx":[1],
		"minrow": 0,
		"maxrow": 0,
		"allowdel": false,
		"fieldlist": [
			{
				"label": "Image",
				"field": "nnproductimage_url",
				"subtotalid": "",
				"value": "",
				"input": "image",
				"imageformat":"jpg", // OPTIONAL: [png, jpg] default png
				"mode": "readonly"
			},
			{
				"label": "Description",
				"field": "nnproductimage_name",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "readonly"
			},
			{
				"label": "Main Imaage",
				"field": "nnproductimage_primary",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "readonly",
				"info": "Only one main image"
			},
		],
		"footerlist": [
		]
	},
	{
		"subformid": "nnproducttag",
		"label": "Categories",
		"table": "nnproducttag",
		"sort": "nnproducttag_id ",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 0,
		"allowdel": false,
		"fieldlist": [
			{
				"label": "Category",
				"field": "nntag_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "readonly"
			}
		],
		"footerlist": [
		]
	}
];

const ProductapprovalPage = ({location}) => {
	const [productdata, setProductdata] = useState({});

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		//var customparam = {};
		var customparam = JSON.parse(JSON.stringify(params));
		var flagapproved = false;
		var curdocstatus = 0;

		if (params.hasOwnProperty('docstatus_id')) {
			if (params.docstatus_id) {
				curdocstatus = parseInt(params.docstatus_id, 10);
				if (curdocstatus === 3) {
					// Approved
					flagapproved = true;
				}
			}
		}

		if (flagapproved === false) {
			// Just save, no other action needed
			webappsAPI.savePayload(entity, customparam, token, payloadlist, subformpayloadlist, function(response){
				callback(response);
			});
			return;
		}

		// Create Product
		customparam = JSON.parse(JSON.stringify(productdata));
		customparam.subform = [];

		//callback({"status":"Error"}); return;

		webappsAPI.savePayload("nnproduct", customparam, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				// Product ID created, assign to subform rows to attach to product
				customparam = JSON.parse(JSON.stringify(params));
				customparam.subform = [];
				if (params.hasOwnProperty("subform")) {
					var subformidx = 0;
					var srcsubformidx = 0;
					var rowidx = 0;
					while (srcsubformidx < params.subform.length) {
						if (params.subform[srcsubformidx].table.toLowerCase() !== "nnproductrequesthistory") {
							customparam.subform.push(params.subform[srcsubformidx]);
							rowidx = 0;
							while (rowidx < customparam.subform[subformidx].rowdata.length) {
								customparam.subform[subformidx].rowdata[rowidx].nnproduct_id = response.documentid;
								rowidx++;
							}
							subformidx++;
						}
						srcsubformidx++;
					}
				}
				webappsAPI.savePayload(entity, customparam, token, payloadlist, subformpayloadlist, function(requestresponse){
					negosyonowAPI.activateEntity(response.documentid,token, "nnproduct").then(nnactivateresponse => {
						callback(requestresponse);
					});
				});
			} else {
				callback(response);
			}
		});
	} // customSubmit


	function customSetFormData(newformdata)
	{
		const skipfields = ["nnproductrequest_id", "documentid", "docstatus_id", "nnproductrequest_usernotes", "nnproductrequest_feedback", "nnproductrequest_created", "nnproductalias_name"];
		var tmpproductdata = {};
		var tmpfield = "";

		for (var name in newformdata) {
			if (skipfields.includes(name)) {
				continue;
			}
			if (name === "nnproductalias_id") {
				if (null !== newformdata[name]) {
					tmpproductdata[name] = newformdata[name];
				} else {
					tmpproductdata[name] = 0;
				}
			} else {
				tmpfield = name.replace("nnproductrequest_", "nnproduct_");
				tmpproductdata[tmpfield] = newformdata[name];
			}
		}
		setProductdata(tmpproductdata);
	}


	return <ProductRequestEditorPage
				location={location}
				allowadd={false}
				customSearchFilter={[{"field":"docstatus_id", "operation":"<>", "value": 1}]} // hide drafts
				formFields={[basicFields.concat(productDimensionfields)]}
				editSubFormFields={subformFields}
				customSubmit={customSubmit}
				customSetFormData={customSetFormData}
			/>

}


export default ProductapprovalPage;
